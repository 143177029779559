import * as React from "react";
import { graphql } from "gatsby";
import BlogCardGrid from "../components/Blogs/Cards/Grid";
import { defaultFields as leadFormFields } from "../config/ContactForm.data";

import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function BlogListing({ data }) {
  const seo = createSeoData({
    title: "Lingua Fonica Blogs",
    description:
      "Keep up to date on all the languages we offer, with interesting articles written by authors with relevant experience.",
  });

  const hero = {
    heroTitleLine1: "Lingua Fonica",
    heroTitleLine2: "blog",
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <BlogCardGrid data={data.allContentfulBlogPost} />
    </Layout>
  );
}

// hero section data query
export const query = graphql`
  {
    allContentfulBlogPost (sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          heroImage {
            description
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  height: 200
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          body {
            raw
          }
          createdAt
        }
      }
    }
  }
`;
